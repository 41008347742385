/* Apply Google Fonts */
body {
  font-family: 'Open Sans', sans-serif; /* Default body font */
}

h3, h1 {
  font-family: 'Roboto', sans-serif; /* Use Roboto for headers */
  font-weight: 700;
}

.App {
  text-align: center;
  background-color: #1a1a1a;
  color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
}

.App-header {
  font-size: calc(10px + 2vmin);
  padding: 20px;
}

.App-logo {
  height: 150px;
  pointer-events: none;
  margin-bottom: 20px;
}

.App-tabs {
  margin: 20px 0;
}

.App-tabs button {
  background-color: #2c2c2c;
  color: white;
  padding: 10px 20px;
  margin: 0 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}

.App-tabs .active-tab {
  background-color: #1e90ff;
  color: white;
}

.App-showcase, .Websites-showcase {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 items per row */
  gap: 60px;
  width: 100%;
  padding: 20px;
}

.App-item, .Website-item {
  background-color: #2c2c2c;
  border-radius: 10px;
  padding: 20px;
  transition: transform 0.3s;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.App-item:hover, .Website-item:hover {
  transform: scale(1.05);
}

.App-item-image {
  width: 50%;
  height: auto;
  object-fit: contain;
  border-radius: 10px;
}

.App-item h3, .Website-item h3 {
  margin-top: 10px;
  color: white;
  font-family: 'Roboto', sans-serif;
}

.App-details {
  margin-top: 10px;
  font-size: 1.1rem;
  font-family: 'Open Sans', sans-serif;
}

.Websites-showcase a {
  color: #1e90ff;
  text-decoration: none;
}

.Websites-showcase a:hover {
  text-decoration: underline;
}

.loading-spinner {
  font-size: 24px;
  color: #333;
  text-align: center;
  margin-top: 50px;
}

.seo-suggestions {
  background-color: #333;
  padding: 10px;
  border-radius: 8px;
  margin-top: 10px;
}

.seo-suggestions h4 {
  margin-bottom: 5px;
  color: #1e90ff;
}

.seo-suggestions ul {
  list-style-type: disc;
  padding-left: 20px;
}

.seo-suggestions li {
  color: #fff;
}


@media (max-width: 1024px) {
  .App-showcase, .Websites-showcase {
    grid-template-columns: repeat(2, 1fr); /* 2 items per row */
    gap: 30px;
  }
}

@media (max-width: 768px) {
  .App-showcase, .Websites-showcase {
    grid-template-columns: 1fr; /* 1 item per row */
    gap: 20px;
  }
}

